var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap doc_req_pop", staticStyle: { width: "950px" } },
    [
      _c("form", { attrs: { id: "frm" } }, [
        _c(
          "button",
          {
            staticClass: "layer_close",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("span", [_vm._v("close")])]
        ),
        _c(
          "div",
          { staticClass: "popup_cont" },
          [
            _vm.parentInfo.reqCatCd === "15"
              ? _c("h1", { staticClass: "page_title text_center" }, [
                  _vm._v(" C.O.D REQUEST "),
                  _c("br"),
                  _vm._v(" (CHANGE OF DESTINATION) "),
                ])
              : _c("h1", { staticClass: "page_title text_center" }, [
                  _vm._v(" Application For Shipback "),
                ]),
            _c("div", { staticClass: "content_box" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 1. Overview "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" Date "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.today)),
                    ]),
                    _c("td"),
                  ]),
                  _vm._m(1),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "text_center", attrs: { rowspan: "2" } },
                      [_vm._v(" FROM ")]
                    ),
                    _c("td", [_vm._v(_vm._s(_vm.formData.shprCstEnm))]),
                    _c("td", [_vm._v(_vm._s(_vm.formData.shprCstNm))]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.shprCstTelNo,
                            expression: "formData.shprCstTelNo",
                          },
                        ],
                        attrs: {
                          id: "shprCstTelNo",
                          type: "text",
                          placeholder: "Tel",
                        },
                        domProps: { value: _vm.formData.shprCstTelNo },
                        on: {
                          blur: function ($event) {
                            return _vm.checkValidationInputInfo("shprCstTelNo")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formData,
                              "shprCstTelNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.shprCstEml,
                            expression: "formData.shprCstEml",
                          },
                        ],
                        attrs: {
                          id: "shprCstEml",
                          type: "text",
                          placeholder: "E-mail",
                        },
                        domProps: { value: _vm.formData.shprCstEml },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formData,
                                "shprCstEml",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkErrorVisible("shprCstEml")
                            },
                          ],
                          blur: function ($event) {
                            return _vm.checkValidationEmail("shprCstEml")
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "text_center", attrs: { rowspan: "2" } },
                      [_vm._v(" B/L Shipper ")]
                    ),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.formData.actShprCstEnm)),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 2. B/L Information "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(2),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" B/L No. "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(" " + _vm._s(_vm.formData.blNo) + " "),
                    ]),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" POL/POD "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formData.polPlcNm) +
                          " / " +
                          _vm._s(_vm.formData.podPlcNm)
                      ),
                    ]),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" VSL/VOY "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        _vm._s(_vm.formData.vslNm) +
                          " / " +
                          _vm._s(_vm.formData.voyNo)
                      ),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 3. Request Details "),
              ]),
              _vm.parentInfo.reqCatCd === "15"
                ? _c("table", { staticClass: "tbl_row mt10" }, [
                    _vm._m(3),
                    _c(
                      "tbody",
                      [
                        _c("tr", [
                          _c("th", { staticClass: "text_center" }, [
                            _vm._v(" Change to "),
                          ]),
                          _c(
                            "td",
                            [
                              _c("e-auto-complete-place", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  "auto-focus": true,
                                  id: "codPodPod",
                                  value: _vm.formData.updtPodPortCd,
                                  "ctr-cd": _vm.pod.ctrCd,
                                  "plc-cd": _vm.pod.plcCd
                                    ? _vm.pod.plcCd
                                    : _vm.formData.updtPodPortCd,
                                  "is-char": true,
                                  "is-char-alert": true,
                                },
                                on: { change: _vm.changeTo },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._l(_vm.containerInfo, function (vo, idx) {
                          return _c("tr", { key: "cntr_" + idx }, [
                            _c("th", { staticClass: "text_center" }, [
                              _vm._v(" CNTR No. "),
                            ]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.cntrNo,
                                    expression: "vo.cntrNo",
                                  },
                                ],
                                staticStyle: { width: "200px" },
                                attrs: {
                                  id: "cntrNo_" + idx,
                                  type: "text",
                                  maxlength: "11",
                                },
                                domProps: { value: vo.cntrNo },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        vo,
                                        "cntrNo",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.changeOnlyUpperEng(
                                        $event,
                                        vo,
                                        "cntrNo",
                                        idx
                                      )
                                    },
                                  ],
                                  blur: function ($event) {
                                    return _vm.checkValidationCntrNo(idx)
                                  },
                                },
                              }),
                              idx === 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon plus",
                                      staticStyle: { "margin-left": "5px" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.addBLContainer(idx)
                                        },
                                      },
                                    },
                                    [_vm._v(" plus ")]
                                  )
                                : _vm._e(),
                              idx !== 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon minus",
                                      staticStyle: { "margin-left": "5px" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeBLContainer(idx)
                                        },
                                      },
                                    },
                                    [_vm._v(" minus ")]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ])
                : _c("table", { staticClass: "tbl_row mt10" }, [
                    _vm._m(4),
                    _c(
                      "tbody",
                      [
                        _c("tr", [
                          _vm._m(5),
                          _c(
                            "td",
                            [
                              _c("e-auto-complete-place", {
                                attrs: {
                                  "auto-focus": true,
                                  id: "shipPolPol",
                                  "ctr-cd": _vm.pol.ctrCd,
                                  "plc-cd": _vm.pol.plcCd,
                                  "is-char": true,
                                  "is-char-alert": true,
                                },
                                on: { change: _vm.shipBackPol },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("e-auto-complete-place", {
                                attrs: {
                                  "auto-focus": true,
                                  id: "shipPodPod",
                                  "ctr-cd": _vm.pod.ctrCd,
                                  "plc-cd": _vm.pod.plcCd,
                                  "is-char": true,
                                  "is-char-alert": true,
                                },
                                on: { change: _vm.shipBackPod },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._l(_vm.containerInfo, function (vo, idx) {
                          return _c("tr", { key: "cntr_" + idx }, [
                            _c("th", { staticClass: "text_center" }, [
                              _vm._v(" CNTR No. "),
                            ]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.cntrNo,
                                    expression: "vo.cntrNo",
                                  },
                                ],
                                staticStyle: { width: "200px" },
                                attrs: {
                                  id: "cntrNo_" + idx,
                                  type: "text",
                                  maxlength: "11",
                                },
                                domProps: { value: vo.cntrNo },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        vo,
                                        "cntrNo",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.changeOnlyUpperEng(
                                        $event,
                                        vo,
                                        "cntrNo",
                                        idx
                                      )
                                    },
                                  ],
                                  blur: function ($event) {
                                    return _vm.checkValidationCntrNo(idx)
                                  },
                                },
                              }),
                              idx === 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon plus",
                                      staticStyle: { "margin-left": "5px" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.addBLContainer(idx)
                                        },
                                      },
                                    },
                                    [_vm._v(" plus ")]
                                  )
                                : _vm._e(),
                              idx !== 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon minus",
                                      staticStyle: { "margin-left": "5px" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeBLContainer(idx)
                                        },
                                      },
                                    },
                                    [_vm._v(" minus ")]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "text_center mt10" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.codShipbackInsert()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.062")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.reqTempSaveYn === "Y",
                      expression: "reqTempSaveYn === 'Y'",
                    },
                  ],
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      return _vm.codShipbackDelete()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("art.CMBA660.007")))]
              ),
              _vm._v(" "),
              _vm.parentInfo.reqCatCd === "15"
                ? _c(
                    "a",
                    {
                      staticClass: "button gray lg",
                      attrs: { href: "#none" },
                      on: {
                        click: function ($event) {
                          return _vm.reqCodSave()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("js.booking.044")))]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "button gray lg",
                      attrs: { href: "#none" },
                      on: {
                        click: function ($event) {
                          return _vm.reqShipBackSave()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("js.booking.044")))]
                  ),
              _vm._v(" "),
              _vm._v("  "),
              _c(
                "a",
                {
                  staticClass: "button gray lg",
                  on: {
                    click: function ($event) {
                      _vm.$emit("close")()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.057")))]
              ),
            ]),
            _c(
              "win-layer-pop",
              { staticClass: "popup_dim" },
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.uploadParam },
                      on: {
                        close: _vm.closePopup,
                        "set-dext-file-info": _vm.arrangeUploadParam,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "text_center" }, [_vm._v(" To ")]),
      _c("td", { attrs: { colspan: "2" } }, [_vm._v("고려해운(주)")]),
      _c("td"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "80%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "text_center" }, [
      _vm._v(" Shipback"),
      _c("br"),
      _vm._v(" POL/POD "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }